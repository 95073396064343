export default {
	login: {
		slogan: "Cloud platform management system",
		describe: "",
		signInTitle: "Sign in",
		accountLogin: "Account sign in",
		phoneLogin: "Mobile sign in",
		phonePlaceholder: "Mobile",
		phoneError: "Please input mobile",
		emailLogin:"Email sign in",
		emailPlaceholder: "Email",
		emailError: "Please input email",
		msgPlaceholder: "SMS Code",
		msgError: "Please input sms code",
		msgGet: "Get SMS Code",
		msgSent: "SMS sent to mobile number",
		oauthLogin: "OAuth sign in",
		rememberMe: "Remember me",
		forgetPassword: "Forget password",
		signIn: "Sign in",
		userCode: "code",
		userPlaceholder: "user / phone / email",
		userError: "Please input a user name",
		PWPlaceholder: "Please input a password",
		PWError: "Please input a password",
		admin: "Administrator",
		user: "User",
		smsPlaceholder: "SMS Code",
		smsError: "Please input sms code",
		smsGet: "Get SMS Code",
		smsSent: "SMS sent to mobile number",
		noAccount: "No account?",
		createAccount: "Create a new account",
		themeTitle: "Change Theme",
		langTitle: "Change Language",
		wechatLoginTitle: "QR code sign in",
		wechatLoginMsg:
			"Please use wechat to scan and log in | Auto scan after 3 seconds of simulation",
		wechatLoginResult:
			"Scanned | Please click authorize login in the device",
	},
	user: {
		dynamic: "Dynamic",
		info: "User Info",
		settings: "Settings",
		nightmode: "night mode",
		nightmode_msg: "Suitable for low light environment",
		language: "language",
		language_msg:
			"Translation in progress,Temporarily translated the text of this view",
	},
};
